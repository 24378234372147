.header{
    margin:20px;
  }
  .header>h1{
    text-align:center;
    font-size:3rem;
    color: #838383;
  }
  .header>p{
    text-align:center;
    font-size:1.5rem;
    font-style:italic;
    color: #838383;
  }

@media screen and (max-width:767px) {
    .header{
      margin-top:150px;
    }
}