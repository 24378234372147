* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body{ background:url(https://images.unsplash.com/photo-1495195129352-aeb325a55b65?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1776&q=80);
  background-size:cover;
  background-position:right;
  background-attachment:fixed;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.h1, p {
  margin-bottom: 1rem;
}
a {
  text-decoration: none;
}
.main {
  padding: 7rem 1rem 1rem;
}

.blur-card {
  /*background: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.6); */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;

  color: #232323;
}

#dark .blur-card {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
#light .blur-card {
  background: rgba(255, 255, 255, 0.2);
  color: #535353;
}

.paragraph {
  text-align: justify;
  text-justify: auto;
}


.is-hidden {
  display: none;
}

$mycolor1: #d9303e;
