.row_dishes {
	margin-top: 5px;
}

.line {
	width: 50%;
	border-bottom: 1px solid #d9303e;
	margin-top: 5px;
}

.dishes {
	font-family: 'Great Vibes', cursive;
	font-size: 30px;
}
.desc_dishes {
	font-size: 20px;
	margin-left: 20px;
}

.price {
	font-family: 'Great Vibes', cursive;
	font-size: 30px;
	color:#d9303e;
	text-align: center;
}

@media screen and (max-width:767px) {
    .dishes {
        text-wrap:nowrap;
        font-size: 20px;
    }
    .desc_dishes {
        font-size: 14px;
        margin-left: 20px;
    }
    .price {
        text-wrap:nowrap;
        position:relative;
        text-align: right;
    }
}