.foodcard{
  display:flex;
  flex-direction:column;
  width:340px;
  margin-bottom:60px;
}
.foodcard>div{
  box-shadow:0 15px 20px 0 rgba(0,0,0,0.5);
  border-radius: 5px;
}
.foodcard-image {
  width:340px;
  height:250px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: -1;
}

#dark .foodcard-image {
  background: rgba(0, 0, 0, 0.6);
}
#light .foodcard-image {
  background: rgba(255, 255, 255, 0.2);
}

.food-noimage {
    width:0px;
    height:125px;
}

.foodcard-image>img{
  width:100%;
  height:100%;
  object-fit:scale-down;
  object-position:bottom;
}
.foodcard-text{
  margin:-30px auto;
  margin-bottom:-50px;
  height:300px;
  width:300px;
  background-color:#232323;
  color:#fff;
  padding:20px;
}
.foodcard-meal-type{
  font-style:italic;
}
.foodcard-title{
  font-size:2.2rem;
  margin-bottom:20px;
  margin-top:5px;
  color:#d9303e;
}
.foodcard-body{
  font-size:1.25rem;
}
.foodcard-price{
  width:100px;
  height:100px;
  background-color:#d9303e;
  color:#fff;
  margin-left:auto;
  font-size:2rem;
  display:flex;
  justify-content:center;
  align-items:center;
}
