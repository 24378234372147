@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Sacramento&family=Lobster');

$mycolor1: #d9303e;
$mycolor2: #535353;

.welcome-card {

    margin: auto;
    padding: 2em;
    border-radius: 1em;
    border: 1px solid transparentize(#fff, 0.5);
    text-shadow: 0 0 1em transparentize(#000, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 1em;
    
    .welcome {
      font-family: 'Sacramento', cursive;
      font-size: 2em;
    }
    
    .title {
      font-family: 'Great Vibes', cursive;
      font-size: 5em;
      
      span {
        display: block;
        &:nth-child(1){
          padding-left: 2em;
          color: $mycolor1;
        }
        &:nth-child(2){
          padding-left: 10em;
          font-size: 40px;
        }
      }
    }
}

#dark .welcome-card  {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
#light .welcome-card  {
  background: rgba(255, 255, 255, 0.2);
	color: #535353;
}

.intro-image {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  background-color: $mycolor2;
  border: 2px solid $mycolor1;
  border-radius: 16px;
}

@media screen and (max-width:767px) {
  .welcome-card {
    .title {
      font-family: 'Great Vibes', cursive;
      font-size: 4em;
      
      span {
        display: block;
        &:nth-child(1){
          padding-left: 1em;
        }        
        &:nth-child(2){
          padding-left: 4em;
          font-size: 30px;
        }
      }
    }
  }
}