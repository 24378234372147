.mcarousel {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
}

.img_carousel {
    width:100%;
    height:400px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
.img_carousel>img {
    width:100%;
    height:100%;
    object-fit:contain;
    object-position:center;
    border-radius: 10px;
}

#dark .img_carousel>img {
    background-color: #939393;
}
