.footer
{
	width: 100%;
	background: #232323;
	padding-top: 60px;
	padding-bottom: 60px;
}
.footer_logo_title
{
	font-size: 60px;
	line-height: 0.75;
	color: #FFFFFF;
}
.footer_logo_subtitle
{
	font-size: 20px;
	text-transform: uppercase;
	color: #FFFFFF;
	letter-spacing: 0.680em;
	line-height: 0.75;
	margin-top: 15px;
}
.copyright
{
	font-size: 15px;
	color: rgba(255,255,255,0.41);
	line-height: 1.2;
	margin-top: 20px;
}
.footer_about a
{
	color: #a5a4a4;
}
.footer_about a:hover
{
	color: #d9303e;
}

@media screen and (max-width:767px) {
	.footer_about {
		padding-top: 20px;
	}
	.footer_logo_subtitle
	{
		line-height: 1;
	}
}