.menu {
	width:100%;
	padding-top: 100px;
}

.section-title {
	font-family: 'Lobster', cursive;
    color: #d9303e;
}

.section-subtitle {
	font-family: 'Great Vibes', cursive;
	font-size: 40px;
}

#dark .section-subtitle {
	color: #fff;
}
#light .section-subtitle {
	color: #535353;
}



.menu_container {
	width:100%;
	display:flex;
	justify-content:space-around;
	flex-wrap:wrap;
	padding:40px 20px;
}