.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    min-height: 75px;
    padding: 1rem;


    background-color: #232323;
    color: #fff;
    z-index: 5;
}

.navbar_links {
    display: flex;
}

.navbar_link {
    padding: 0 0.3rem;
    color: inherit;
}
.navbar_link:hover {
    color: #d9303e;
}

.resa_color {
    color: #d9303e;
    font-weight: bold;
}

.navbar_burger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
}

.navbar_logo {
	transform: translateY(3px);
}
.navbar_logo a
{
	display: block;
}
.navbar_logo a > div:first-child
{
	font-size: 36px;
	line-height: 0.75;
	color: #FFFFFF;
}
.navbar_logo a > div:last-child
{
	font-size: 12px;
	text-transform: uppercase;
	color: #FFFFFF;
	letter-spacing: 0.680em;
	line-height: 0.75;
	margin-top: 12px;
}

.resa {
    display:none
}

.theme_button {
    display: block;
    position:absolute;
    right: 30px;
}

@media screen and (max-width:767px) {
    .navbar_links {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: 0;
        height: 100vh;
        padding: 2rem;
        visibility: hidden;
        background-color: #000;

    }
    .show-nav .navbar_links {
        right: 0;
        width: 100vw;
        visibility: visible;
    }
    .navbar_link {
        display: block;
        padding: 1.5rem;
        font-size: 5vw;

    }
    .navbar_burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
    .navbar_burger:hover {
        cursor: pointer;
    }
    .burger_bar,
    .burger_bar::before,
    .burger_bar::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: #fff;
        transition: all 0.3s ease-in-out;

    }
    .burger_bar::before,
    .burger_bar::after {
        content: "";
        position: absolute;
        left: 0;
    }
    .burger_bar::before {
        transform: translateY(-12px)
    }
    .burger_bar::after {
        transform: translateY(+12px)
    }
    .show-nav .burger_bar {
        width: 0;
        background: transparent;
    }
    .show-nav .burger_bar::before {
        transform: rotate(45deg);
    }
    .show-nav .burger_bar::after {
        transform: rotate(-45deg);
    }
    .resa {
        display:block;
    }
    .theme_button {
        display: none;
    }
}