
.contact {
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 120px;

    font-family: 'Lobster', cursive;
}

#dark .contact  {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
}
#light .contact  {
    background: rgba(255, 255, 255, 0.2);

}

.contact-title {
    color:#d9303e;
}

.contact-body {
    padding-top:20px;
    text-align: justify;
    text-justify: auto;
    width: 100%;
}

.contact-text {
    padding:30px;
    font-size:2rem;
}

.contact-card {
    padding: 50px;
    border: solid 2px #d9303e;
    font-size: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

#dark .contact-card a {
    color: #fff;
    text-wrap: nowrap;
}

.contact-card a:hover
{
	color: #d9303e;
}

.info {
    font-size: 15px;
}

@media screen and (max-width:767px) {
    .contact-text {
        font-size:1rem;
    }
    .contact_list
    {
        padding-left:30px
    }
    .contact-card {
        padding: 20px;
    }
    .info {
        font-size: 12px;
    }
    .info a{
        text-align: center;
    }
}